.diagram {

    background-color: #f0f0ff;
    resize: horizontal;
    overflow-x: auto;
}
.diagramDragging {
    opacity: 20%;
}

.diagram-resizer {

    width: 1rem;
    height: 1rem;
    position: absolute;
    border-radius: 100% 0 0 0;
    bottom: 0;
    right: 0;
    cursor: col-resize;
    background-color: #2d2d2d;
    opacity: 20%;
    z-index: 99999;
    transition: all 0.5s ease-in
}
.diagram-resizer:hover {
    opacity:70%;
    background-color: #03855a;
    width: 2rem;
    height: 2rem;
    cursor: col-resize;

}

